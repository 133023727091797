import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Kontakt"
          subheader='Sie können mich telefonisch oder per Email erreichen.
          Es wird bei freier Kapazität zunächst ein Termin für eine Psychotherapeutische Sprechstunde vereinbart.
          Gegebenenfalls finden weitere Sitzungen statt, bzw. wird ein Antrag auf Psychotherapie gestellt.
          Die Krankenkassen übernehmen in der Regel alle Kosten.'
        />
        <Divider />
        <ContactForm />
        <p>
        Bedenken Sie, dass Emails grundsätzlich nicht sicher sind. Bitte schicken Sie mir keine
        vertraulichen Informationen. Ich empfehle die telefonische Kontaktaufnahme.
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
