import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import { Box, Text, Flex, Button } from 'theme-ui'
import Section from '@components/Section'
import CardList from '@components/CardList'
import Card from '@components/Card'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import { useBlogTags, useBlogCategories } from '@helpers-blog'

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Flex>
            <Box>
              <Section title='Mein Verständnis von Psychotherapie'>
              <Text variant='p'>
Psychotherapie sehe ich als Hilfe zur Selbsthilfe an. Besonders wichtig ist mir 
eine vertrauensvolle und solide Therapeutische Beziehung, die nur durch
Begegnung auf Augenhöhe entstehen kann. Neben störungsspezifischen Therapiebausteinen
können schematherapeutische, imaginative, ressourcenorientierte und vor allem 
validierende Inhalte Anwendung finden.
</Text>
<Text variant='p'>
In der Psychotherapie geht es um Schwierigkeiten, aber auch um Stärken. 
Ein sich schrittweise entwickelndes Verständnis für eigene Gefühle, Gedanken und 
Verhaltensweisen kann hilfreich sein. Im Allgemeinen wird davon ausgegangen, dass 
Verhaltenstherapie dabei viel auf Veränderung setzt. Aber auch Akzeptanz der 
eigenen Wahrnehmungsverarbeitung und damit einhergehende Besonderheiten können
im Mittelpunkt stehen. Die Funktionalität von so genannten Symptomen soll greifbarer und durch 
Erkennen möglicher Ursachen verstehbarer werden. Dabei 
versuche ich, eine unterstützende Begleitung zu sein.
              </Text>
              </Section>
      <Divider />
      <Divider />
              <Section title='Psychotherapeutische Sprechstunde'>
              <Text variant='p'>
Im Rahmen der Psychotherapeutischen Sprechstunden geht es um die Einordnung Ihres Anliegens.
Hilfreich hierbei ist eine biografische Anamnese. So können derzeitige und frühere
Symptome passender eingeschätzt und Ressourcen erkannt werden. 
Ich bemühe mich darum, mögliche Diagnosen individuell
zu erläutern. Es wird geklärt, ob eine ambulante Psychotherapie in Frage kommen könnte.

</Text>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
